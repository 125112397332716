.overview{
	> .table{
		display:block;
	}

	.table{
		margin-bottom: 0px;
		.actions{
			font-size:20px;
			span.space{
				padding: 0px 6px;
			}
		}		
	}
	
	.nav-tabs{
		border-bottom:0px;
		padding:0px 13px;
	}
	
	.tab-content{
		padding: 1px 1rem;
		border: 1px solid var(--bs-border-color);
		border-radius: 0.375rem;
	}
	
	.table-container{
		border: 1px solid var(--bs-border-color);
		border-radius: 0.375rem;
		overflow: hidden;
		
		tbody > tr:last-child td{
			border-bottom-width:0px;
		}
	}
}